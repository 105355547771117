/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Layout } from '../_metronic/layout';
import AppPages from './AppPages';
import { AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import { getLocalStorage } from '../app/utils/helpers';
import { AUTH_DATA } from '../app/utils/const';
import SignUp from '../Pages/SignUp.jsx';
import VerifyOTP from '../Pages/VerifyOTP.jsx';
import RelevancyDemo  from '../Pages/RelevancyDemo/image.jsx';
import { shallowEqual } from "react-redux";
import Login from "./modules/Auth/pages/Login";
import MyComponent from "../Pages/AI";
import BrandHygen from '../Pages/BrandHygene/index.jsx';
import QuickList from "../Pages/UnauthorizedQuickList/index.js"
export function Routes() {
  const isAuthorized = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));

  return (
    <Switch>
      {/* <Route  path="/scanAi" component={() => <MyComponent/>} /> */}
      {!isAuthorized ? (
        <>
          <Route
            exact
            path='/incident-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/impression-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/visit-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route
            exact
            path='/event-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/install-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/app-reengagement-event-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/external-embeded'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/publisher-summary'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/app/publisher-summary'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/app-reengagement-click-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/brand-relevancy/downloads'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/brand-relevancy/add'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/web/vast-creative/upload'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/brand-relevancy/edit'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/click-integrity/campaign_geo_location'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/click-integrity/rule_configuration'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/click-integrity/rule_configuration/edit'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/click-integrity/rule_configuration/whitelisting_edit'
            component={() => <Redirect to='/auth/login' />}
          />
          {/* <Route
            exact
            path="/click-integrity/rule_configuration_edit"
            component={() => <Redirect to="/auth/login" />}
          />
          <Route
            exact
            path="/click-integrity/whitelisting_edit"
            component={() => <Redirect to="/auth/login" />}
          /> */}
          <Route
            exact
            path='/click-integrity/generate_url'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/event-publisher-summary'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/app-event-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/click-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/add-white-listing'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route
            exact
            path='/white-listing'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/event-report'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/webfraud-custom-report'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route
            exact
            path='/app-event-publisher-report'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route
            exact
            path='/app-install-publisher-report'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/app/report/event'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route
            exact
            path='app/report/install'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/add-configuration'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/webfraud-publisher-report'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/degree-search'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/demo'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route
            exact
            path='/verifications/address'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/issue'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/packages'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/deepdive'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/report'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/ticketing-system'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/issues'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/user'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/verification'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='digital_foot_prints'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/changepassword'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/package-detail'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/usermanagement'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/package-user'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/mtrackit'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/mtrackit_dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/mtrackit_capping'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route
            exact
            path='/add-user'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/mtrackit_campaigns'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/mtrackit_publisher'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route
            exact
            path='/impression-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/mtrackit_report'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/visit-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          {/* Adword affiliate and adward competitor */}
          <Route
            exact
            path='/adword_affiliate'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/adword_competitor'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/coupons'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/keyword-bidding-download'
            component={() => <Redirect to='/auth/login' />}
          />

          {/*Brand Safety Dashboard*/}

          <Route
            exact
            path='/bs-dashboard'
            component={() => <Redirect to='/auth/login' />}
          />
          <Route
            exact
            path='/bs-download'
            component={() => <Redirect to='/auth/login' />}
          />

          <Route exact path='/' component={AuthPage} />
          <Route exact path='/sign-up' component={SignUp} />
          <Route exact path='/brand-hygen' component={BrandHygen} />
          <Route exact path='/relevancy-demo' component={RelevancyDemo} />
          <Route exact path='/brandHygiene' component={RelevancyDemo} />
          <Route exact path='/quick-list' component={QuickList} />
          <Route exact path='/verify-otp' component={VerifyOTP} />
          <Route path='/error' component={ErrorsPage} />
        </>
      ) : (
        <>
          <Layout>
            <AppPages />
          </Layout>
        </>
      )}
    </Switch>
  );
}
