import React from 'react';
import Select from 'react-select';

export const SelectField = React.forwardRef(props => {
  const {
    label,
    placeholder,
    option,
    field,
    setFieldValue,
    values,
    disabled,
    form: { touched, errors },
  } = props;
  const handleOnchange = e => {
    setFieldValue(field.name, e.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <>
      <label>{label}</label>
      <Select
        isDisabled={disabled}
        options={option}
        name={field.name}
        placeholder={placeholder}
        className={
          touched[field.name] && errors[field.name]
            ? 'is-invalid'
            : values[field.name]
            ? 'is-valid'
            : ''
        }
        onChange={option => handleOnchange(option)}
        value={
          field.value
            ? option?.find(option => option.value === field.value)
            : ''
        }
        menuPlacement="auto"
      />
      <span style={{ color: 'red' }} className='danger'>
        {touched[field.name] && errors[field.name]}
      </span>
    </>
  );
});
